<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import NouvelleArticles from "@/components/Nouvelle/NouvelleArticles.vue";
import AppMobile from "@/components/AppMobile.vue";

export default {
  name: "Nouvelle",
  components :{
    AppMobile,
    NouvelleArticles,
    AppHeader,
    AppFooter,
  }
}
</script>

<template>
  <div class="screen flex flex-col size-full gap-8 items-center">
    <AppHeader></AppHeader>
    <AppMobile></AppMobile>
    <NouvelleArticles></NouvelleArticles>
    <AppFooter></AppFooter>
  </div>
</template>

<style scoped>

</style>