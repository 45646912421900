<script>
export default {
  name: "StatutTexte"
}
</script>

<template>
  <div class="flex w-full items-center px-6 justify-center mt-56 titre">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">BUT ET COMPOSITION DE LA FEDERATION</h1>
    </div>
  </div>
  <section class="flex flex-col p-6 justify-center items-center gap-8 w-full mb-24">

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 1er : Formation et but</h1>
      <p>L’Association fondée le 8 Septembre 1954 sous le titre “Fédération Nationale du Mérite Maritime et de la Médaille d’Honneur des Marins” est régie par la loi du 1er juillet 1901 et son décret du 16 août 1901.</p>
      <br>
      <p>Son sigle est FNMM</p>
      <br>
      <p>Sa durée n’est pas limitée.</p>
      <br>
      <p>Elle a pour buts :</p>
      <br>
      <p>- de veiller au prestige de l’Ordre du Mérite Maritime et à celui de la Médaille d’Honneur des Marins;</p>
      <p>- de créer et de maintenir entre tous ses membres des liens d’amitié et de solidarité;</p>
      <p>- d’aider, par tous moyens a sa disposition, les associations méritantes dans leurs actions en faveur des Oeuvres Maritimes;</p>
      <p>- de contribuer a la conservation du patrimoine culturel maritime au moyen, notamment, de son Centre de Documentation Maritime;</p>
      <p>- riche de la diversité, de la compétence et de l’expérience de ses membres, d’être le conseil des autorités et organismes concernés pour contribuer au maintien et au développement de l’image de qualité d’une France maritime.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 2 : Siège social</h1>
      <p>Le Siège social de la Fédération est fixé à Marseille. Il pourra être transféré, en tout autre lieu, après décision prise en Assemblée Générale Extraordinaire.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 3 : Actions et moyens</h1>
      <p>L’action de la Fédération s’exerce indépendamment de toutes considérations d’ordre politique, syndical ou confessionnel.</p>
      <br>
      <p>Tous les moyens légaux pouvant servir les intérêts moraux et matériels de ses membres pourront être mis en oeuvre.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 4 : Organisation territoriale</h1>
      <p>La Fédération est organisée en sections qui représentent localement la Fédération et ses intérêts.</p>
      <br>
      <p>Chaque section couvre au minimum un département et regroupe les adhérents locaux.</p>
      <br>
      <p>Chaque section est créée par l'Assemblée Générale de la Fédération sur proposition du Conseil d'Administration.</p>
      <br>
      <p>Les sections ne disposent pas d'une personnalité morale propre.</p>
      <br>
      <p>Les sections sont administrées par un bureau comprenant au moins un Président, un Vice-Président, un Secrétaire et un Trésorier, élus pour trois ans, au cours de son assemblée générale locale réunissant les membres de la section à jour de leur cotisation.</p>
      <br>
      <p>Le Bureau national est systématiquement invité aux réunions de l'assemblée générale de chaque section.</p>
      <br>
      <p>La section a une autonomie d'organisation et doit rendre compte de son activité à chaque Assemblée générale de la Fédération ou du Conseil d'Administration lorsqu'il le demande.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 5 : Composition</h1>
      <p>La Fédération se compose exclusivement de membres personnes physiques :</p>
      <br>
      <p>- Actifs;</p>
      <p>- à Vie;</p>
      <p>- Associés;</p>
      <p>- d'Honneur</p>
      <p>- et de membres Bienfaiteurs, personnes physiques ou morales.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 6 : Perte de la qualité de membre</h1>
      <p>La qualité de membre de la Fédération se perd :</p>
      <br>
      <p>- par le décès;</p>
      <p>- par la démission;</p>
      <p>- pour non paiement des cotisations;</p>
      <p>- par la radiation ( ou suspension ) de l’Ordre;</p>
      <p>- pour motif grave, sur décision du Conseil d'Administration prise à la majorité qualifiée des deux tiers après avoir proposé d'entendre l'intéressé, quelle que soit sa qualité de membre.</p>
    </div>
  </section>

  <div class="flex w-full items-center px-6 justify-center">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">RESSOURCES</h1>
    </div>
  </div>
  <section class="flex flex-col p-6 justify-center items-center gap-8 w-full mb-24">

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 7 : Recettes</h1>
      <p>Les ressources de la Fédération comprennent :</p>
      <br>
      <p>- les cotisations des adhérents;</p>
      <p>- les aides financières de l'Etat, Régions, Départements, Communes, Etablissements publics et autres organismes;</p>
      <p>- les dons et les apports des bienfaiteurs.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 8 : Cotisations</h1>
      <p>Le montant des cotisations est fixé chaque année par l’assemblee générale sur proposition du conseil d’administration.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 9 : Fonds de réserve</h1>
      <p>Il est constitué un fonds de réserve où sont versés, chaque année, les excédents qui ne sont pas nécessaires dans l'immédiat au fonctionnement de la Fédération.</p>
      <br>
      <p>La quotité et la composition du fonds de réserve sont décidées par le Conseil d'Administration.</p>
    </div>

  </section>

  <div class="flex w-full items-center px-6 justify-center">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">ADMINISTRATION ET FONCTIONNEMENT</h1>
    </div>
  </div>
  <section class="flex flex-col p-6 justify-center items-center gap-8 w-full mb-24">

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 10 : Assemblée Générale</h1>
      <h1 class="text-2xl">Composition</h1>
      <p>L'Assemblée Générale de la Fédération se compose de tous les membres définis à l'article 5, à jour de leurs cotisations de l'année précédente, ou en étant dispensés.</p>
      <br>
      <p>Tout membre de la Fédération peut mandater, par écrit, un autre membre pour le représenter. Le mandataire ainsi désigné ne pourra être porteur de plus de dix mandats.</p>
      <br>
      <p>Toutefois chaque Président de section, ou son représentant, pourra être porteur, sans limitation, des mandats des seuls adhérents de la section.</p>
      <br>
      <p>Le nombre de mandats confiés au Président de la Fédération n'est pas limité.</p>
      <br>
      <p>Les mandats devront être expédiés au siège 15 jours avant la date de l'Assemblée Générale (cachet de la poste faisant foi).</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Périodicité et lieu</h1>
      <p>L'Assemblée Générale est convoquée, par le Président, au moins une fois par an sur proposition, ou à la demande, du Conseil d'Administration ainsi que, sur des questions précises, à la demande de la moitié des membres de la Fédération.</p>
      <br>
      <p>Elle se déroulera, en principe, en mai, au sein de la Section se portant volontaire pour en assurer l'organisation.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Ordre du jour</h1>
      <p>L'Assemblée Générale ne peut délibérer que sur les questions portées à l'ordre du jour. Celui-ci figure dans le "Mini-bulletin" adressé à tous les Adhérents.</p>
      <br>
      <p>Les propositions, écrites, des Adhérents et des membres des Bureaux des sections, sont présentées à L'Assemblée Générale, pour information et avis, après accord du Conseil d'Administration de la Fédération.</p>
      <br>
      <p>Ces propositions sont traitées avec les questions diverses.</p>
      <br>
      <p>Toutes les décisions sont prises à la majorité des membres présents ou représentés.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Déroulement de l'Assemblée Générale</h1>
      <p>L'Assemblée Générale entend le rapport du Président sur la situation de la Fédération, le rapport d'activité du Secrétaire Général, l'exposé du Trésorier sur les comptes de l'exercice écoulé et sur le budget prévisionnel de l'exercice suivant, ainsi que le rapport des Contrôleurs auxComptes.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 11 : Assemblée Générale Extraordinaire</h1>
      <p>Lorsque les intérêts de la Fédération l'exigent, sur décision du Président, du Conseil d'Administration ou à la demande de la moitié des adhérents de la Fédération, une Assemblée Générale Extraordinaire est convoquée sur une ou des questions précises, dans les formes identiques à celles d'une Assemblée Générale.</p>
      <br>
      <p>Toutes les décisions sont prises à la majorité des 2/3 des membres, présents ou représentés.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 12 : Conseil d’Administration</h1>
      <p>La Fédération est administrée par un Conseil d'Administration composé :</p>
      <br>
      <p>- du Président National, du Vice-Président, du Secrétaire Général et du Trésorier Général;</p>
      <p>- du Président de chaque Section ou, éventuellement, d'un membre de la Section élu à cette fin;</p>
      <p>- des membres de la Fédération élus par l'Assemblée Générale.</p>
      <br>
      <p>Le Président de la Fédération et le Bureau sont élus par le Conseil d'Administration parmi ses membres. Ils sont proclamés par l'Assemblée Générale.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 13 : Fonctionnement du Conseil d’Administration</h1>
      <p>Le Conseil d'Administration se réunit au moins une fois par semestre, sur convocation de son Président ou à la demande de la moitié de ses membres.</p>
      <br>
      <p>Le Conseil d'Administration ne peut valablement délibérer que si la moitié de ses membres est présente ou représentée. Dans le cas contraire, une autre réunion sera convoquée dans un délai de quinze jours et, dans ce cas, le Conseil d'Administration sera compétent quel que soit le nombre de membres présents ou représentés.</p>
      <br>
      <p>Les Administrateurs qui ne peuvent assister au Conseil ou se faire remplacer par un membre de leur Section, ont la faculté de donner pouvoir à un autre Administrateur pour se faire représenter.</p>
      <br>
      <p>Le Président de la Fédération ne peut détenir que deux pouvoirs ; les autres Administrateurs, un seul.</p>
      <br>
      <p>Les décisions sont prises à la majorité des présents et des représentés, la voix du Président de la Fédération étant prépondérante en cas d'égalité des voix.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 14 : Bureau du Conseil d’Administration</h1>
      <p>Pour constituer le Bureau, le Conseil d'Administration élit parmi ses membres :</p>
      <br>
      <p>- Le Président de la Fédération.</p>
      <br>
      <p>Celui-ci propose alors à l'approbation du Conseil :</p>
      <br>
      <p>- le Vice-Président;</p>
      <p>- le Secrétaire Général;</p>
      <p>- le Trésorier Général;</p>
      <p>- 4 membres.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 15 : Contrôleurs aux Comptes</h1>
      <p>Deux Contrôleurs aux Comptes, choisis parmi les adhérents ou extérieurs à la Fédération, sont élus, par l'Assemblée Générale, pour une durée de trois ans.</p>
      <br>
      <p>Ils sont rééligibles.</p>
    </div>
  </section>

  <div class="flex w-full items-center px-6 justify-center">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">CENTRE DE DOCUMENTATION MARITIME</h1>
    </div>
  </div>
  <section class="flex flex-col p-6 justify-center items-center gap-8 w-full mb-24">
    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 16 : Centre de Documentation Maritime</h1>
      <p>La Fédération a créé un Centre de Documentation Maritime pour répondre à l'un de ses buts.</p>
      <br>
      <p>Ce Centre de Documentation Maritime fait partie intégrante de la Fédération.</p>
    </div>
  </section>

  <div class="flex w-full items-center px-6 justify-center">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">MODIFICATION DES STATUTS ET DISSOLUTION</h1>
    </div>
  </div>
  <section class="flex flex-col p-6 justify-center items-center gap-8 w-full mb-24">
    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 17 : Modification des statuts</h1>
      <p>Les statuts ne peuvent être modifiés que par une assemblée générale extraordinaire, sur proposition du Conseil d’Administration ou sur demande de la moitié des adhérents de la Fédération.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 18 : Dissolution</h1>
      <p>La dissolution de la Fédération ne peut résulter que d’une décision prise, après délibération, par une Assemblée Générale Extraordinaire.</p>
      <br>
      <p>En cas de dissolution, l’Assemblée Générale Extraordinaire désigne un ou plusieurs Commissaires chargés de la liquidation des biens de la Fédération, qui seront, s’il y a lieu, dévolus conformément à l'article 9 de la loi du 1er juillet 1901.</p>
      <br>
      <p>Les documents et ouvrages détenus par le Centre de Documentation Maritime seront proposés à un autre organisme concourant aux mêmes buts, les biens meubles entrant, eux, dans le cadre de la liquidation.</p>
    </div>
  </section>

  <div class="flex w-full items-center px-6 justify-center">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">REGLEMENT INTERIEUR</h1>
    </div>
  </div>
  <section class="flex flex-col p-6 justify-center items-center gap-8 w-full mb-24">
    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 19 : Réglement Intérieur</h1>
      <p>Un Règlement intérieur, élaboré par le conseil d’administration, est soumis à l’approbation de i’Assemblée Générale.</p>
      <br>
      <p>Ce règlement est destiné à préciser divers points des Statuts et, plus particulièrement, ceux qui ont trait à l’organisation interne et financière de la Fédération.</p>
      <br>
      <p>Il définit l’organisation et le rôle des sections.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Article 20 : Entrée en vigueur</h1>
      <p>Les présents Statuts, modifiés par l'Assemblée Générale Extraordinaire du 18 mai 2017, annulent et remplacent ceux précédemment en vigueur.</p>
    </div>
  </section>
</template>

<style scoped>
@media (max-width: 1024px) {
  .titre{
    margin-top: 100px;
  }
}
</style>