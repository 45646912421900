<template>
  <footer class="size-full flex font-WorkSans p-12 border-t-2 items-center flex-col bg-[#072653] text-white">
    <div class="banner flex size-full flex-col mb-16 justify-center items-center">
      <img src="@/assets/img/logo.png" class="w-24 rounded-full mb-4">
      <h1 class="font-black text-3xl">FNMM</h1>
      <span class="text-center mt-4 border-b-2 pb-10">Association reconnue d'Intérêt général  - Art. 200-1-b et 238 bis-1-a du CGI</span>
    </div>
    <div class="flex-row foot flex justify-center  size-full px-64 gap-32">
      <div class="polities size-full flex-col flex gap-2">
        <h1 class="font-semibold text-0xl underline">Politiques</h1>
        <a href="#" class="hover:underline w-auto">Politique de confidentialité</a>
        <a href="#" class="hover:underline"  target="_blank">Conditions d'utilisation</a>
        <a href="#" class="hover:underline"  target="_blank">Politique de cookies</a>
        <a href="#" class="hover:underline"  target="_blank">Politique de gestion des droits d'auteur</a>
        <a href="#" class="hover:underline"  target="_blank">Conditions de la messagerie</a>
        <a href="#" class="hover:underline"  target="_blank">Politique de sécurité</a>
      </div>
      <div class="socials size-full flex flex-col justify-end">
        <h1 class="font-semibold text-0xl underline">Réseaux</h1>
        <a href="https://www.instagram.com/merite_maritime/" target="_blank" class=" flex hover:underline">Nous sommes sur instagram</a>
        <a href="https://www.linkedin.com/company/fnmm/" target="_blank" class=" flex hover:underline">Nous sommes sur Linkedin</a>
        <a href="/contact" class=" flex hover:underline"  target="_blank">Nous contacter</a>
      </div>
      <div class="socials size-full flex flex-col justify-end">
        <h1 class="font-semibold text-0xl underline">Support</h1>
        <a href="" class=" flex hover:underline"  target="_blank">Contacter le support technique</a>
        <a href="" class=" flex hover:underline"  target="_blank">FAQ</a>
        <a href="" class=" flex hover:underline"  target="_blank">Support</a>
      </div>
    </div>
    <small class="mt-24"><a href="https://hbc-group.fr" target="_blank">Site créé par High Base Code</a></small>
  </footer>
</template>

<style scoped>
@media (max-width: 730px) {
  .foot{
    flex-direction: column;
    padding: 0;
  }
}
</style>

<script>
export default {
  name : 'AppFooter',
}
</script>