<template>
    <div class="flex w-full h-full flex-col p-6 overflow-y-auto overflow-x-hidden">
      <div class="box w-full flex-col gap-4 flex">
        <input v-model="article.h1" type="text" name="h1" id="h1" placeholder="Titre principal" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
          <input type="file" @change="handleFileChange" id="img" accept="image/jpeg, image/png">
        <img v-if="previewImage" :src="previewImage" alt="Image preview" class="w-full max-w-xs">
        <textarea v-model="article.text" name="text" id="text" placeholder="Sujet" class="bg-neutral-100 flex w-full p-6"></textarea>
      </div>
      <button @click="saveChanges" class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4">Publier</button>
      <button class="flex w-full justify-center items-center bg-neutral-400 px-6 py-4 text-white hover:bg-neutral-300 rounded mt-4">Annuler</button>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    name: 'VueArticleCreator',
    data() {
      return {
        article: {
          h1: '',
          img: null,
          text: ''
        },
        previewImage: null
      }
    },
    methods: {
      saveChanges() {
        let formData = new FormData();
        formData.append('h1', this.article.h1);
        formData.append('img', this.article.img);
        formData.append('text', this.article.text);
  
        axios.post('https://api.meritemaritime-fnmm.com/backoffice/createarticle', formData)
          .then(response => {
            console.log('Article créé avec succès:', response.data);
            window.location.reload();
            // Afficher un message de succès ou rediriger l'utilisateur
          })
          .catch(error => {
            console.error('Erreur lors de la création de l\'article:', error);
            // Afficher un message d'erreur à l'utilisateur
          });
      },
      handleFileChange(event) {
        this.article.img = event.target.files[0];
        this.previewImage = URL.createObjectURL(event.target.files[0]); // Créer l'URL de l'image pour la prévisualisation
      }
    }
  };
  </script>
  
  <style scoped>
  textarea {
    min-height: 300px;
  }
  </style>
  