<script>
export default {
  name: "MedailleTexte"
}
</script>

<template>
  <div class="flex w-full items-center px-6 justify-center mt-56 titre">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">La Médaille d'Honneur des marins du commerce et de la pêche</h1>
    </div>
  </div>
  <section class="flex flex-col p-6 justify-center items-center gap-8 w-full mb-24">
    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">A L'ANCIENNETE L'HONNEUR SE DOIT</h1>
      <p>Telle devrait être la devise que nous proposons avec fierté aux titulaires de la Médaille d'Honneur des marins du Commerce et de la Pêche.</p>
      <p>Le chercheur passionné qui cheminerait sur les sentiers de la découverte en remontant le temps, tout en essayant de découvrir quelles ont été dans le passé les récompenses qui devaient honorer les vertus fondamentales des gens de mer, ne manquerait pas de constater que ce n’est que vers le milieu du xviiie siècle que l’on vit apparaître les premières marques extérieures de reconnaissance ; elles étaient destinées surtout à honorer la fidélité sur les contrôles militaires d’un régiment ou d’un rôle d’équipage.</p>
      <br>
      <p>Ce n’est qu’en 1754, que l’on voit apparaître les premiers insignes qui furent attribués aux hommes justifiant dans le même régiment, de trois engagements de huit ans « sans défaillance ». Cet insigne qui honorait surtout la fidélité, était en laine de la couleur des parements de l’habit, au centre figuraient deux épées croisées en pal, nouées par une faveur, l’ensemble était brodé dans un encadrement de fils d’or.</p>
      <br>
      <p>C’est le roi Louis XV qui officialisa cette distinction par une ordonnance en date du 16 avril 1771, sur une proposition du Marquis de Montenard, Secrétaire d’Etat à la Guerre. Au cours d’une prise d’Armes, le récipiendaire, après avoir prêté le serment conventionnel, recevait, en présence d’un front de troupes sous les armes, son insigne avec un Brevet. Il s’engageait ainsi à ne servir d’autre souverain que son roi, en récompense, la haute paye lui était accordée ; elle était de 5 sous par jour pour un bas Officier et de 4 sous pour un soldat</p>
      <p>Ces dispositions vis-à-vis de la troupe eurent pour effet de provoquer une émulation assez inhabituelle, ce qui explique pourquoi l’on vit surgir de derrière les fourgons, des vétérans qui justifiaient parfois de plus de 48 années de présence sous les drapeaux : on leur accorda le double médaillon.</p>
      <br>
      <p>On rapporte à ce sujet que c’est le Duc d’Aiguillon qui, un jour, insista vivement auprès du roi Louis XVI, pour lui présenter un homme exceptionnel. Il s’agissait d’un soldat d’un régiment qui rentrait d’Amérique, il totalisait plus de 72 ans de service ! Il s’était enrôlé à 12 ans comme tambour pour suivre son père aux armées. Il avait parcouru depuis tous les champs de bataille des campagnes de Louis XIV, Louis XV et Louis XVI dans le même régiment — qui du reste avait changé plusieurs fois de nom —, il venait d’atteindre 84 ans.</p>
      <p>Le roi lui proposa la Croix de Saint-Louis avec un viatique, cela va de soi, mais il préféra garder le privilège d’arborer ses trois médaillons, à condition toutefois de recevoir jusqu’à la fin de ses jours, trois fois la haute paye. C’est à l’époque du retour des guerres d’indépendance américaines, aux environs de 1785, que l’on procéda aux premières attributions de médaillons de vétérance pour les marins ; ceux-ci, qui étaient cousus sur les dolmans, furent bientôt remplacés par des plaques émaillées suspendues par une faveur rouge.</p>
      <br>
      <p>Les marins portèrent la plaque émaillée blanche sur laquelle figurait au centre une ancre d’or et, brochant sur le tout, deux sabres d’abordage entrecroisés. Elle était supportée par un ruban blanc, divisé par une large bande rouge au centre et deux étroits liserés bleus sur les bords.</p>
      <p>Mais les événements révolutionnaires vinrent bientôt perturber cet état de choses. Tout d’abord, c’est la Convention Nationale qui, par le Décret du 15 octobre 1792, supprima tous les Ordres de Chevalerie en exigeant que les insignes de ces Ordres fussent déposés dans les Mairies.</p>
      <p>Ces directives ne concernèrent pas les Médaillons de vétérans qui continuèrent à être distribués jusqu’en 1795, date à laquelle les Brevets cessèrent d’être établis.</p>
      <br>
      <p>Dès son accession au pouvoir, le Premier consul, fort épris des traditions antiques qui étaient au goût du jour, innova en réactivant une coutume militaire romaine qui consistait à offrir aux auteurs d’action d’éclat, une arme d’honneur personnalisée. C’est ainsi que prit corps l’étonnante collection d’armes de ce genre qui nous soit encore possible de contempler dans nos musées. Ce sont ces sabres, fusils, piques, lances, briquets, haches, trompettes, fifres et même baguettes de tambour, qui firent sans doute la fierté de leurs titulaires, car elles étaient dédicacées avec le détail de leurs exploits.</p>
      <br>
      <p>Mais l’on ne pouvait récompenser ainsi tous les gens de mérite, une fabrique d’armes n’y aurait pas suffi ; c’est alors que l’on vit apparaître pour les marins, l’insigne de la hache de poitrine. Il représentait une hache d’abordage dont la lame portait un étendard drapé, en bronze, sur lequel s’inscrivait la dédicace. Très élégant, moins encombrant, puisqu’il tenait facilement dans la main, il ne vécut pas assez longtemps pour compter de nombreux titulaires.</p>
      <br>
      <p>Le musée de la Légion d’honneur en possède un très beau spécimen qui mémorialise l’action de Jean-Louis LEGRAS, Maître d’Equipage qui, après avoir sauvé la vie de quatre de ses camarades qui allaient se noyer, pointa à couler plusieurs barques anglaises qui venaient de l’attaquer, au cours des engagements du 27 thermidor de l’an IX.</p>
      <br>
      <p>Bonaparte, qui avait en tête les projets de son futur règne, cherchait à réinstaurer un système de récompenses qui puisse être à la fois dévolu aux civils et aux militaires et qui donnerait ainsi satisfaction à tout le monde.</p>
      <br>
      <p>Nous savons que c’est par l’ordonnance du 27 floréal de l’an X (19 mai 1802) qu’il créa la Légion d’honneur, laquelle fut ratifiée par la suite par un vote du Corps législatif. Cette distinction nationale, qui est organisée à l’inspiration des Ordres de l’ancien régime, mais que l’on appela « Légion », à la romaine, pour faire diversion sur l’esprit soupçonneux des anciens conventionnels, suscita très vite un vif intérêt.</p>
      <p>L’on s’empressa d’inclure dans les premières promotions les titulaires d’armes d’honneur qui, de droit furent admis dans la Légion. Tous les titulaires du nouvel Ordre devaient prêter serment à la couronne, c’est sans doute la raison pour laquelle cette obligation fut supprimée à l’avènement de la Troisieme République, en 1871.</p>
      <br>
      <p>Il n’en reste pas moins que depuis, le prestige de la Légion d’honneur est toujours resté intact, ce qui tend à prouver, s’il en était besoin, que notre Ordre National correspond bien aux services que son fondateur en attendait lors de son institution.</p>
      <br>
      <p>Les insignes de vétérance disparurent avec l’ancien régime, et il fallut attendre le déclin du xixe siècle pour voir apparaître une distinction dont les aspirations étaient à peu près comparables dans la forme et dans le fond.</p>
      <br>
      <p>Nous voulons parler de la Médaille d’honneur des marins du Commerce et de la Pêche, qui fut instituée par la Loi du 14 décembre 1902 elle s’est imposée à l’époque pour combler le "vide" qui s’était creusé à la suite de l’éclosion de plusieurs décorations ayant pour intention de récompenser l’endurance, l’abnégation et souvent le dévouement d’une élite de gens très attachants.</p>
      <p>Ils exerçaient leurs activités dans des Services tels que : la Police, les Postes, la Douane, les Contributions directes, l’Enseignement, les Chemins de fer, les Mines, sans oublier pour autant, les Services pénitenciers ou le personnel non militaire des Arsenaux.</p>
      <p>Tous ces Services qui avaient été honorés à l’époque par des médaillés devant rendre hommage à leur sens des obligations et du Devoir. Il convenait de faire quelque chose pour que ne soient pas en reste les équipages des navires de Pêche et de la Marine Marchande dont la ténacité dans l’épreuve avait été maintes fois citée élogieusement.</p>
      <br>
      <p>Cette distinction spécialement destinée aux inscrits maritimes à qui nul n’avait encore songé fut la première marque d’honneur qui nous ait été réservée.</p>
      <br>
      <p>Pierre-Lionel OVIGNY. Médaille d'Honneur des marins.</p>
    </div>
  </section>
</template>

<style scoped>
@media (max-width: 1024px) {
  .titre{
    margin-top: 100px;
  }
}
</style>