<template>
  <section class="flex w-full h-full">
    <div class="sidebar overflow-y-auto overflow-x-hidden flex-col flex gap-2 w-full h-full border-r p-6">
      <div class="search">
        <input type="text" name="search" id="search" placeholder="Rechercher un article" v-model="searchQuery" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
        <button @click="createArticle" class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4">Créer un article</button>
        <hr class="w-full flex border my-4">
      </div>
      <div v-for="article in filteredArticles" :key="article.id_article_familly" class="article flex px-6 py-4 bg-neutral-100 flex-col gap-4 rounded">
        <h1>{{ article.title }}</h1>
        <small>{{ new Date(article.date).toLocaleDateString() }}</small>
        <div class="btn flex w-full items-center gap-4">
          <button @click="editArticle(article.id_article)" class="bg-neutral-200 p-2 flex w-full justify-center rounded">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pencil">
              <path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z"/>
              <path d="m15 5 4 4"/>
            </svg>
          </button>
          <button @click="deleteArticle(article.id_article)" class="bg-red-400 flex w-full text-white p-2 rounded justify-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg></button>
        </div>
      </div>
    </div>
    
    <!-- Composants affichés en fonction de l'état -->
    <VueArticle v-if="!creatingArticle" ref="vueArticle"></VueArticle>
    <VueArticleCreator v-if="creatingArticle" @cancel="cancelCreate"></VueArticleCreator>
  </section>
</template>

<script>
import axios from 'axios';
import VueArticle from "@/components/Dashboard/Nouvelles/VueArticle.vue";
import VueArticleCreator from "@/components/Dashboard/Nouvelles/VueArticleCreator.vue";

export default {
  name: 'NouvelleDash',
  components: {
    VueArticle,
    VueArticleCreator,
  },
  data() {
    return {
      articles: [],
      searchQuery: '',
      creatingArticle: false,
    };
  },
  async beforeRouteEnter(to, from, next) {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error('Token not found in localStorage');
        next('/');
        return;
      }

      const response = await axios.post('https://api.meritemaritime-fnmm.com/checkadmin/', { token });
      console.log('Admin role check response:', response.data);

      if (!response.data) {
        next('/');
      } else {
        next();
      }
    } catch (error) {
      console.error('Error checking admin role:', error);
      next('/');
    }
  },
  mounted() {
    this.fetchArticles();
  },
  methods: {
    async deleteArticle(id_article) {
    try {
      const response = await axios.post(`https://api.meritemaritime-fnmm.com/backoffice/delete/article/${id_article}`);
      console.log('Article deleted:', response.data);
      // Optionnel : Rafraîchir la liste des articles après suppression
      this.fetchArticles();
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'article:', error);
    }
  },
    async fetchArticles() {
      try {
        const response = await axios.get('https://api.meritemaritime-fnmm.com/backoffice/listarticle');
        this.articles = response.data;
      } catch (error) {
        console.error('Erreur lors de la récupération des articles:', error);
      }
    },
    async editArticle(id_article) {
      try {
        const response = await axios.get(`https://api.meritemaritime-fnmm.com/backoffice/editarticle/${id_article}`);
        localStorage.setItem('id_article', id_article);
        console.log('Article data:', response.data);
        localStorage.setItem('editedArticle', JSON.stringify(response.data));
        this.creatingArticle = false; // On désactive la création d'article après édition
        this.$nextTick(() => {
          this.$refs.vueArticle.refreshComponent(); // Assurez-vous que refreshComponent() est accessible
        });
      } catch (error) {
        console.error('Erreur lors de la récupération de l\'article:', error);
      }
    },
    createArticle() {
      this.creatingArticle = true; // Activer l'état de création d'article
    },
    cancelCreate() {
      this.creatingArticle = false; // Annuler la création d'article
    }
  },
  computed: {
    filteredArticles() {
      return this.articles.filter(article => 
        article.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  }
};
</script>

<style scoped>
.sidebar {
  max-width: 380px;
}
</style>
