<script>
  export default {
    name:'PrixVue',
  }
</script>

<template>
  <div class="flex w-full items-center px-6 justify-center mt-56 titre">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">PRIX LITTERAIRES</h1>
    </div>
  </div>
  <hr class="w-full flex border max-w-screen-lg">
  <div class="flex w-full items-center px-6 justify-center">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">PRIX 2024</h1>
    </div>
  </div>
  <section class="flex w-full items-center justify-center p-6 flex-col gap-4">
    <div class="flex w-full items-center justify-center">
      <div class="containt flex w-full gap-8 max-w-screen-lg">
        <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
          <h1 class="text-2xl">Prix Ecume de Mer</h1>
          <hr class="border w-full flex">
          <img src="../../assets/img/prix/img1.png" class="border-2">
        </div>
        <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
          <h1 class="text-2xl">Prix Cdt Jean LOREAU</h1>
          <hr class="border w-full flex">
          <img src="../../assets/img/prix/img2.png" class="border-2">
        </div>
      </div>
    </div>
    <a href="/" class="btn bg-[#072653] text-white px-6 py-2 rounded">Communiqué de PRESSE</a>
    <hr class="w-full flex border max-w-screen-lg">
    <img src="@/assets/img/prix/img3.png" alt="">
    <a href="/" class="btn bg-[#072653] text-white px-6 py-2 rounded">Communiqué de PRESSE</a>
  </section>
  <hr class="w-full flex border max-w-screen-lg">
  <div class="flex w-full items-center px-6 justify-center">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">Remise des Prix  2023 à Pornic</h1>
    </div>
  </div>
  <section class="flex w-full items-center justify-center p-6 flex-col gap-4">
    <div class="flex w-full items-center justify-center">
      <div class="containt flex w-full gap-8 max-w-screen-lg">
        <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
          <h1 class="text-2xl"></h1>
          <hr class="border w-full flex">
          <img src="../../assets/img/prix/img4.png" class="border-2">
        </div>
        <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
          <h1 class="text-2xl"></h1>
          <hr class="border w-full flex">
          <img src="../../assets/img/prix/img5.png" class="border-2">
        </div>
      </div>
    </div>
    <a href="/" class="btn bg-[#072653] text-white px-6 py-2 rounded">Communiqué de PRESSE</a>
  </section>
  <hr class="w-full flex border max-w-screen-lg">
  <div class="flex w-full items-center px-6 justify-center">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">Saint-Gilles Croix de Vie délibération du Jury 2022 Maison des Ecrivains de la Mer</h1>
    </div>
  </div>
  <section class="flex w-full items-center justify-center p-6 flex-col gap-4">
    <div class="flex w-full items-center justify-center">
      <div class="containt flex w-full gap-8 max-w-screen-lg">
        <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
          <h1 class="text-2xl">Prix  Ecume de mer   </h1>
          <hr class="border w-full flex">
          <img src="../../assets/img/prix/img6.png" class="border-2">
        </div>
        <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
          <h1 class="text-2xl">Prix   Cdt  Jean Loreau</h1>
          <hr class="border w-full flex">
          <img src="../../assets/img/prix/img7.png" class="border-2">
        </div>
      </div>
    </div>
    <a href="/" class="btn bg-[#072653] text-white px-6 py-2 rounded">Communiqué de PRESSE</a>
  </section>
</template>

<style scoped>
@media (max-width: 1024px) {
  .titre{
    margin-top: 100px;
  }
}
@media (max-width: 800px){
  .containt{
    flex-direction: column;
  }
}
</style>