<script>
import axios from 'axios';

export default {
  name: "ArticleTexte",
  data() {
    return {
      articleData: null
    };
  },
  mounted() {
    this.fetchArticleData();
  },
  methods: {
    fetchArticleData() {
      const id = this.getArticleIdFromURL();
            axios.get(`https://api.meritemaritime-fnmm.com/article/${id}`)
        .then(response => {
          this.articleData = response.data;
        })
        .catch(error => {
          console.error("Erreur lors de la récupération des données de l'article:", error);
        });
    },
    getArticleIdFromURL() {
      const url = window.location.href;
      const id = url.substring(url.lastIndexOf('/') + 1);
      return id;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
            const formattedDate = `${date.getDate()}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} à ${date.getHours()}:${date.getMinutes()}`;
      
      return formattedDate;
    }
  }
};
</script>


<template>
  <section v-if="articleData" class="flex flex-col w-full max-w-screen-lg mt-56 titre gap-4 px-6 mb-24">
    <div class="flex gap-4 flex-col" v-for="(article, index) in articleData" :key="index">
      <h1 :class="index === 0 ? 'text-5xl' : 'text-3xl mt-12 mb-4'" v-if="article.title">{{ article.title }}</h1>
      <small class="" v-if="article.date">{{ formatDate(article.date) }}</small>
      <img class="flex w-full mb-4" v-if="article.photo" :src="article.photo" alt="Image de l'article">
      <div class="files flex-wrap flex gap-4 w-full">
        <button class="flex bg-[#3E5C8E] p-2 rounded text-white items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-text"><path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"/><path d="M14 2v4a2 2 0 0 0 2 2h4"/><path d="M10 9H8"/><path d="M16 13H8"/><path d="M16 17H8"/></svg>
          <span>Congrès 2024</span>
        </button>
        <button class="flex bg-[#3E5C8E] p-2 rounded text-white items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-text"><path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"/><path d="M14 2v4a2 2 0 0 0 2 2h4"/><path d="M10 9H8"/><path d="M16 13H8"/><path d="M16 17H8"/></svg>
          <span>Congrès 2024</span>
        </button>
      </div>
      <p class="text-xl" v-if="article.description">{{ article.description }}</p>
    </div>
  </section>
</template>




<style scoped>
@media (max-width: 1024px) {
  .titre{
    margin-top: 100px;
  }
}
</style>