<template>
  <div class="flex w-full items-center px-6 justify-center mt-56 titre">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">Contact</h1>
    </div>
  </div>
  <div class="widget max-w-screen-lg flex bg-neutral-100 rounded-xl p-6 items-center gap-6 mx-6">
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-triangle-alert">
      <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3"/>
      <path d="M12 9v4"/>
      <path d="M12 17h.01"/>
    </svg>
    <span>Tout abus de la messagerie entraînera un bannissement immédiat et définitif du site. Merci de respecter les règles d'utilisation pour maintenir un environnement sûr et convivial pour tous.</span>
  </div>
  <section class="flex justify-center w-full items-center p-6 flex-col gap-4 mb-24">
    <form @submit.prevent="handleSubmit" class="flex w-full flex-col gap-4 border-[#072653] border-b-4 p-6 bg-neutral-100 max-w-screen-lg">
      <input v-model="form.lastname" type="text" name="lastname" id="lastname" placeholder="Nom*" class="flex w-full outline-0 border p-4 rounded" required>
      <input v-model="form.email" type="email" name="email" id="email" placeholder="Email*" class="flex w-full outline-0 border p-4 rounded" required>
      <input v-model="form.object" type="text" name="object" id="object" placeholder="Object*" class="flex w-full outline-0 border p-4 rounded" required>
      <textarea v-model="form.message" name="message" id="message" cols="30" rows="10" class="flex w-full outline-0 border p-4 rounded resize-none mb-12" placeholder="Message (max : 350)" maxlength="350" required></textarea>
      <button type="submit" class="bg-[#072653] text-white p-4">Envoyer</button>
      <div v-if="responseMessage" class="mt-4 text-center">
      <p :class="{'text-green-600': responseType === 'success', 'text-red-600': responseType === 'error'}">{{ responseMessage }}</p>
    </div>
      <small>En cliquant sur le bouton "Envoyer", vous acceptez notre politique de confidentialité ainsi que les conditions d'utilisation de notre service de messagerie.</small>
    </form>
    <a href="/" class="hover:underline">Contacter le support technique</a>
   
  </section>
  <hr class="flex w-full border max-w-screen-lg mb-24">
  <Cta></Cta>
</template>

<script>
import axios from 'axios';
import Cta from "@/components/Index/Cta.vue";

export default {
  name: "ContactTexte",
  components: {
    Cta
  },
  data() {
    return {
      form: {
        lastname: '',
        email: '',
        object: '',
        message: ''
      },
      responseMessage: '',
      responseType: '' // 'success' or 'error'
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const response = await axios.post('https://api.meritemaritime-fnmm.com/mail', this.form);
        console.log('Success:', response.data);
        this.responseMessage = 'Email envoyé avec succès et message enregistré dans la base de données';
        this.responseType = 'success';
      } catch (error) {
        console.error('Error:', error);
        this.responseMessage = 'Erreur lors de l\'envoi de l\'email ou de l\'enregistrement dans la base de données';
        this.responseType = 'error';
      }
    }
  }
};
</script>

<style scoped>
@media (max-width: 800px) {
  .widget {
    flex-direction: column;
    text-align: center;
  }
}

@media (max-width: 1024px) {
  .titre {
    margin-top: 100px;
  }
}
</style>
