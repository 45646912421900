<script>
import axios from 'axios';

export default {
  name: 'SectionsDash',
  data() {
    return {
      sections: [], // Pour stocker les données reçues de l'API
      sectionDetails: null, // Pour stocker les détails de la section sélectionnée
      title: '', // Lier au champ du titre de la section
      description: '', // Lier au champ de la description de la section
      link: '' // Lier au champ du lien du site
    };
  },
  methods: {
    async fetchSections() {
      try {
        const response = await axios.post('https://api.meritemaritime-fnmm.com/section/list');
        this.sections = response.data;
        localStorage.setItem('sections', JSON.stringify(this.sections));
      } catch (error) {
        console.error('Erreur lors de la récupération des données:', error);
      }
    },
    async fetchSectionDetails(id_section_familly) {
      try {
        const response = await axios.get(`https://api.meritemaritime-fnmm.com/section/${id_section_familly}`);
        this.sectionDetails = response.data;
        localStorage.setItem('sectionDetails', JSON.stringify(this.sectionDetails));

        // Mise à jour des champs avec les données récupérées
        if (this.sectionDetails.length > 0) {
          const details = this.sectionDetails[0]; // Assumant que vous prenez le premier élément
          this.title = details.title || '';
          this.description = details.description || '';
          this.link = details.link || '';
        }

      } catch (error) {
        console.error('Erreur lors de la récupération des détails de la section:', error);
      }
    },
    saveSectionDetails() {
      const updatedDetails = {
        title: this.title,
        description: this.description,
        link: this.link
      };
      localStorage.setItem('sectionDetails', JSON.stringify(updatedDetails));
      // Vous pouvez également envoyer ces détails à l'API si nécessaire
    }
  },
  mounted() {
    this.fetchSections();

    // Récupérer les détails de la section depuis le localStorage s'ils existent
    const storedSectionDetails = localStorage.getItem('sectionDetails');
    if (storedSectionDetails) {
      const details = JSON.parse(storedSectionDetails);
      this.title = details.title || '';
      this.description = details.description || '';
      this.link = details.link || '';
    }
  }
};
</script>


<template>
  <section class="flex w-full h-full">
    <div class="sidebar overflow-y-auto overflow-x-hidden flex-col flex gap-2 w-full h-full border-r p-6">
      <div class="search">
        <input type="text" name="search" id="search" placeholder="Rechercher une section" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
        <button class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4">Créer une section</button>
        <hr class="w-full flex border my-4">
      </div>


      <div v-for="section in sections" :key="section.id" class="article flex px-6 py-4 bg-neutral-100 flex-col gap-4 rounded">
      <h1>{{ section.nom }}</h1>
      <div class="btn flex w-full items-center gap-4">
        <button @click="fetchSectionDetails(section.id_section_familly)" class="bg-neutral-200 p-2 flex w-full justify-center rounded">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pencil">
            <path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z"></path>
            <path d="m15 5 4 4"></path>
          </svg>
        </button>
        <button class="bg-red-400 flex w-full text-white p-2 rounded justify-center">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2">
            <path d="M3 6h18"></path>
            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
            <line x1="10" x2="10" y1="11" y2="17"></line>
            <line x1="14" x2="14" y1="11" y2="17"></line>
          </svg>
        </button>
      </div>
    </div>

      
    </div>




    

    <div class="info flex w-full flex-col gap-2 p-6 border-r">
      <div class="search">
        <input type="text" v-model="title" placeholder="Titre de la section" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
        <textarea v-model="description" placeholder="Description de la section" class="flex w-full px-6 py-4 bg-neutral-100 rounded mt-4"></textarea>
        <input type="text" v-model="link" placeholder="Lien du site" class="flex w-full px-6 py-4 bg-neutral-100 rounded mt-4">
        
        <button class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4">Enregistrer</button>
        <hr class="w-full flex border my-4">
      </div>
      <h1>Membres de la section :</h1>
      <section class="flex w-full infoSection flex-col gap-2 overflow-y-auto overflow-x-hidden">
        <div class="article flex px-6 py-4 bg-neutral-100 w-full items-center gap-4 rounded ">
          <h1>PETITJEAN Eric</h1>
          <div class="flex items-center gap-4">
            <input type="radio" name="1" id="1">
            <small>03/05/2024</small>
            <div class="btn flex w-full items-center gap-4">
              <button  class="bg-neutral-200 p-2 flex w-full justify-center rounded">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pencil"><path data-v-1333a208="" d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z"></path><path data-v-1333a208="" d="m15 5 4 4"></path></svg>
              </button>
              <button class="bg-red-400 flex w-full text-white p-2 rounded justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2" data-v-1333a208=""><path d="M3 6h18" data-v-1333a208=""></path><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" data-v-1333a208=""></path><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" data-v-1333a208=""></path><line x1="10" x2="10" y1="11" y2="17" data-v-1333a208=""></line><line x1="14" x2="14" y1="11" y2="17" data-v-1333a208=""></line></svg>
              </button>
            </div>
          </div>
        </div>
        <div class="article flex px-6 py-4 bg-neutral-100 w-full items-center gap-4 rounded ">
          <h1>GUILLON-VERNE Jean</h1>
          <div class="flex items-center gap-4">
            <input type="radio" name="1" id="2">
            <small>03/05/2024</small>
            <div class="btn flex w-full items-center gap-4">
              <button  class="bg-neutral-200 p-2 flex w-full justify-center rounded">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pencil"><path data-v-1333a208="" d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z"></path><path data-v-1333a208="" d="m15 5 4 4"></path></svg>
              </button>
              <button class="bg-red-400 flex w-full text-white p-2 rounded justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2" data-v-1333a208=""><path d="M3 6h18" data-v-1333a208=""></path><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" data-v-1333a208=""></path><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" data-v-1333a208=""></path><line x1="10" x2="10" y1="11" y2="17" data-v-1333a208=""></line><line x1="14" x2="14" y1="11" y2="17" data-v-1333a208=""></line></svg>
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="infoUser flex w-full flex-col p-6 gap-4">
      <small>Ajouter une nouvelle personne dans la section ou bien cliquer sur le "crayon" pour modifier les informations d'un membre.</small>
      <input type="text" name="fullname" id="fullname" placeholder="Nom complet" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
      <input type="file" id="img" accept="image/jpeg, image/png">
      <input type="text" name="role" id="role" placeholder="Rôle" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
      <button class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4">Enregistrer</button>
    </div>
  </section>
</template>

<style scoped>
.sidebar{
  max-width: 300px;
}

.infoSection .btn{
  max-width: 50px;
}


</style>