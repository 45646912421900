<script>
  export default {
    name :'Social',
  }
</script>

<template>
  <div class="flex w-full items-center px-6 justify-center">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">Nous sommes sur les réseaux</h1>
    </div>
  </div>
  <section class="flex w-full items-center justify-center p-6">
    <div class="containt flex w-full gap-8 max-w-screen-lg mb-24">
      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
        <h1 class="text-2xl">Linkedin</h1>
        <hr class="border w-full flex">
        <a href="https://www.linkedin.com/company/fnmm/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"/><rect width="4" height="12" x="2" y="9"/><circle cx="4" cy="4" r="2"/></svg></a>
      </div>

      <div class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
        <h1 class="text-2xl">Instagram</h1>
        <hr class="border w-full flex">
        <a href="https://www.instagram.com/merite_maritime/" target="_blank" class="btn bg-[#072653] text-white px-6 py-2 rounded"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-instagram"><rect width="20" height="20" x="2" y="2" rx="5" ry="5"/><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"/><line x1="17.5" x2="17.51" y1="6.5" y2="6.5"/></svg></a>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>