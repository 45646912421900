<script>
import AppMobile from "@/components/AppMobile.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import SectionsTexte from "@/components/Section/SectionsTexte.vue";

export default {
  name: "Section",
  components:{
    SectionsTexte,
    AppFooter,
    AppHeader,
    AppMobile

  }
}
</script>

<template>
  <div class="screen flex flex-col w-full gap-8 items-center">
    <AppMobile></AppMobile>
    <AppHeader></AppHeader>
    <SectionsTexte></SectionsTexte>
    <AppFooter></AppFooter>
  </div>
</template>

<style scoped>

</style>