<script>
import AppMobile from "@/components/AppMobile.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import ArticleTexte from "@/components/Nouvelle/Article/ArticleTexte.vue";

export default {
  name: "Article",
  components:{
    ArticleTexte,
    AppFooter,
    AppHeader,
    AppMobile

  }
}
</script>

<template>
  <div class="screen flex flex-col w-full gap-8 items-center">
    <AppMobile></AppMobile>
    <AppHeader></AppHeader>
    <ArticleTexte></ArticleTexte>
    <AppFooter></AppFooter>
  </div>
</template>

<style scoped>

</style>