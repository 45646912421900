<template>
  <nav class="z-20 flex">
    <div class="title" :class="{ 'hidden': scrolled }">
      <div class="box">
        <img src="@/assets/img/logo.png" class="logo-fnmm">
        <div class="box-title">
          <h1 class="text-2xl flex flex-col items-center text-[#072653] text-center">FEDERATION NATIONALE DU MERITE MARITIME ET <br>DE LA MEDAILLE D'HONNEUR DES MARINS<span class="text-center text-lg">Association reconnue d'Intérêt général  - Art. 200-1-b et 238 bis-1-a du CGI</span></h1>
        </div>
      </div>
    </div>
    <div class="nav">
      <img src="@/assets/img/gouv.png" class="flew w-20 absolute left-0 ml-4">
      <div class="dropdown">
        <div class="dropdown-btn active"><router-link to="/">Accueil</router-link></div>
      </div>
      <div class="dropdown">
        <div class="dropdown-btn"><a href="/histoire-maritime">Présentation <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg></a></div>
        <div class="dropdown-content">
          <router-link to="/histoire-maritime">Histoire Mérite Maritime</router-link>
          <router-link to="/conseil-de-l-ordre">Le Conseil de l'Ordre</router-link>
          <router-link to="/medaille-honneur">La médaille d'honneur</router-link>
          <router-link to="/statuts-federation">Statuts Fédération</router-link>
          <!-- <router-link to="#">Organigramme Bureau & CA</router-link> -->
        </div>
      </div>
      <div class="dropdown">
        <div class="dropdown-btn"><a href="/nouvelles-de-la-federation">Actualité FNMM <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg></a></div>
        <div class="dropdown-content">
          <router-link to="/nouvelles-de-la-federation">Nouvelles de la Fédération</router-link>
          <router-link to="/centre-de-documentation-maritime">Centre de Documentation Maritime</router-link>
          <router-link to="/prix-litteraires">Prix Littéraires FNMM</router-link>
        </div>
      </div>
      <div class="dropdown">
        <div class="dropdown-btn"><RouterLink to="/congres-fnmm">Congrès</RouterLink></div>
      </div>
      <div class="dropdown">
        <div class="dropdown-btn"><RouterLink to="/sections">Sections</RouterLink></div>
      </div>
      <div class="dropdown">
        <div class="dropdown-btn"><a href="/les-decores">Les décorés MM-MH <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6"/></svg></a></div>
        <div class="dropdown-content">
          <router-link to="/les-decores">Décorations récentes et Croix 2024</router-link>
          <router-link to="/les-decores">Médaillés d'honneur depuis 1934</router-link>
          <router-link to="/les-decores">Décorés Mérite Maritime depuis 1930</router-link>
        </div>
      </div>
      <div class="dropdown">
        <div class="dropdown-btn"><a href="/contact">Contact</a></div>
      </div>

      <router-link v-if="hasTokenInLocalStorage" to="/espace-membre/panel" class="signin rounded">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user">
          <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"/><circle cx="12" cy="7" r="4"/>
        </svg>
        <span class="login">Profil</span>
      </router-link>
      <router-link v-else to="/espace-membre" class="signin rounded">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-user">
          <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"/><circle cx="12" cy="7" r="4"/>
        </svg>
        <span class="login">Se connecter</span>
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: "AppHeader",
  data() {
    return {
      scrolled: false,
    };
  },
  computed: {
    hasTokenInLocalStorage() {
      return localStorage.getItem('token') !== null;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrolled = window.scrollY > 50;
    },
    toggleMobile() {
      this.$emit('toggle-mobile');
    },
  },
}
</script>



<style scoped>
.title.hidden {
  display: none;
}

nav{
  display: flex;
  position: fixed;
  flex-direction: column;
  width: 100%;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-color: white;
}

.signin{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  height: 45px;
  background-color: #3E5C8E;
  color: white;
  position: absolute;
  right: 0;
  margin-right: 20px;
  grid-gap: 5px;
}

.signin span{
  color: white;
}

.title{
  display: flex;
  width: 100%;
  padding: 20px;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px #d9d9d9;
  transition: display 0.5s ease;
}

.title .box{
  display: flex;
  width: 100%;
  max-width: 21cm;
  grid-gap: 20px;
}

.box-title{
  display: flex;
  width: 100%;
  flex-direction: column;
}

.box-title span{
  display: flex;
  align-items: center;
}

.box-title span img{
  display: flex;
  width: 60px;
  object-fit: cover;
}

.title .box .logo-fnmm{
  display: flex;
  width: 100px;
  object-fit: cover;
}

.nav{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 60px;
}

.dropdown {
  position: relative;
  display: inline-block;
  height: 60px;
  justify-content: center;
}

.dropdown-btn {
  background-color: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dropdown-btn a span{
  margin-left: 5px;
}

.dropdown-btn a{
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.dropdown-btn:hover{
  background-color: #F1F1F1;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  width: 320px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: flex;
  flex-direction: column;
}

.nav-mobile{
  display: none;
}

@media (max-width: 1200px){
  .login{
    display: none;
  }
}

@media  (max-width: 1024px) {
  nav{
    display: none;
  }
}
</style>
