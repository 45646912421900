<script>
import axios from 'axios';

export default {
  name: "GlobalArticles",
  data() {
    return {
      articles: []
    };
  },
  computed: {
    halfDescription() {
      if (this.articles.length > 0 && this.articles[0].description) {
        const description = this.articles[0].description;
        const halfLength = Math.ceil(description.length / 2);
        return description.slice(0, halfLength);
      }
      return '';
    }
  },
  methods: {
    fetchArticles() {
      axios.post('https://api.meritemaritime-fnmm.com/article/list')
        .then(response => {
          this.articles = response.data;
        })
        .catch(error => {
          console.error("There was an error!", error);
        });
    }
  },
  created() {
    this.fetchArticles();
  }
}
</script>
<template>
  <div class="flex w-full items-center px-6 justify-center">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">Actualités</h1>
    </div>
  </div>


  <section class="flex w-full flex-col items-center justify-center p-6 mb-24">
    <a :href="`nouvelles-de-la-federation/article/${articles[0]?.id_aritcle_familly}`" class="widget flex flex-col max-w-screen-lg gap-2">
      <div class="title flex items-center gap-2">
        <div class="p-2 bg-[#072653] rounded">
          <img src="@/assets/img/logo.png" class="flex w-12 rounded-full object-cover min-w-12  ">
        </div>
        <h1 class="flex text-4xl">{{ articles[0]?.title }}</h1>
      </div>
      <img :src="articles[0]?.photo" class=" flex w-full object-cover rounded" alt="Article Image">
      <p>{{ halfDescription }}</p>
    </a>



    <hr class="flex w-full border my-6 max-w-screen-lg">

    <div class="containt flex w-full gap-8 max-w-screen-lg">
      <a v-for="(article, index) in articles.slice(1, 4)" :key="article.id_article" :href="`nouvelles-de-la-federation/article/${article.id_aritcle_familly}`" class="widget flex w-full flex-col gap-2">
        <img :src="article.photo" class="art flex w-full rounded" :alt="article.title">
        <h1 class="text-2xl">{{ article.title }}</h1>
      </a>
    </div>

    <hr class="flex w-full border my-6 max-w-screen-lg">


    <a href="/nouvelles-de-la-federation" class="btn bg-[#072653] text-white px-6 py-2 rounded">
      Voir plus
    </a>
</section>
</template>

<style scoped>
@media (max-width: 800px) {
  .widget .title div{
    display: none;
  }

  .widget h1{
    font-size: 30px;
  }

  .containt{
    flex-direction: column;
  }
}

.art{
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  height: 180px;
  min-height: 180px;
  max-height: 180px;
}
</style>