<script>
import axios from 'axios';

export default {
  name: "DeciresDash",
  data() {
    return {
      formData: {
        name: '',
        year: '',
        quartier: '',
        fonction: ''
      },
      pdfNames: [] // Pour stocker les noms et IDs des PDFs
    };
  },
  mounted() {
    this.fetchPdfNames(); // Appeler la méthode pour récupérer les noms des PDFs lorsque le composant est monté
  },
  methods: {
    // Méthode pour récupérer les noms des PDFs depuis l'API
    fetchPdfNames() {
      axios.get('https://api.meritemaritime-fnmm.com/backoffice/getpdfsection')
        .then(response => {
          this.pdfNames = response.data.map(pdf => ({
            id: pdf.id,
            name: pdf.name
          }));
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des noms des PDFs:', error);
        });
    },

    // Méthode pour gérer l'upload du fichier PDF
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file && file.type === 'application/pdf') {
        const formData = new FormData();
        formData.append('pdf', file);

        axios.post('https://api.meritemaritime-fnmm.com/backoffice/createpdfdecore', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          console.log('PDF téléchargé avec succès', response.data);
          this.fetchPdfNames(); // Mettre à jour la liste des PDFs après l'upload
        })
        .catch(error => {
          console.error('Erreur lors du téléchargement du PDF', error);
        });
      } else {
        alert('Veuillez sélectionner un fichier PDF.');
      }
    },

    // Méthode pour supprimer un PDF par ID
    deletePdf(id) {
      axios.delete('https://api.meritemaritime-fnmm.com/backoffice/pdfdeletesection', {
        data: { id }
      })
      .then(response => {
        console.log('PDF supprimé avec succès', response.data);
        this.fetchPdfNames(); // Mettre à jour la liste des PDFs après la suppression
      })
      .catch(error => {
        console.error('Erreur lors de la suppression du PDF', error);
      });
    },

    // Méthode pour envoyer les données du formulaire
    submitForm() {
      axios.post('https://api.meritemaritime-fnmm.com/backoffice/adddecore', this.formData)
        .then(response => {
          console.log('Données ajoutées avec succès:', response.data);
          // Réinitialiser le formulaire ou afficher un message de succès
          this.formData = { name: '', year: '', quartier: '', fonction: '' };
          // Optionnel : Afficher un message à l'utilisateur
          alert('Les données ont été ajoutées avec succès.');
        })
        .catch(error => {
          console.error('Erreur lors de l\'ajout des données:', error);
          // Optionnel : Afficher un message d'erreur à l'utilisateur
          alert('Une erreur est survenue. Veuillez réessayer.');
        });
    }
  }
};
</script>

<template>
  <div class="sidebar overflow-y-auto overflow-x-hidden flex-col flex gap-2 w-full h-full border-r p-6">
    <div class="search">
      <input type="text" name="search" id="search" placeholder="Rechercher un article" v-model="searchQuery" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
      <hr class="w-full flex border my-4">
    </div>
    <!-- <div  class="article flex px-6 py-4 bg-neutral-100 flex-col gap-4 rounded">
      <h1>test</h1>
      <small>test</small>
      <div class="btn flex w-full items-center gap-4">
        <button class="bg-neutral-200 p-2 flex w-full justify-center rounded">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-pencil">
            <path d="M21.174 6.812a1 1 0 0 0-3.986-3.987L3.842 16.174a2 2 0 0 0-.5.83l-1.321 4.352a.5.5 0 0 0 .623.622l4.353-1.32a2 2 0 0 0 .83-.497z"/>
            <path d="m15 5 4 4"/>
          </svg>
        </button>
        <button class="bg-red-400 flex w-full text-white p-2 rounded justify-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg></button>
      </div>
    </div> -->
  </div>
  <section class="flex size-full flex-col p-6 items-center">
    <div class="manual flex flex-col gap-6 max-w-screen-lg">
      <input type="text" name="name" id="name" v-model="formData.name" placeholder="Nom complet" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
      <div class="infos flex items-center gap-6">
        <input type="text" name="year" id="year" v-model="formData.year" placeholder="Année" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
        <input type="text" name="quartier" id="quartier" v-model="formData.quartier" placeholder="Quartier" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
        <input type="text" name="fonction" id="fonction" v-model="formData.fonction" placeholder="Fonction" class="flex w-full px-6 py-4 bg-neutral-100 rounded">
      </div>
      <button class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4" @click="submitForm">Enregistrer</button>
    </div>
    <hr class="w-full flex border my-4">
    <div class="filepdf flex w-full flex-col max-w-screen-lg gap-6">
      <input type="file" id="pdf" @change="handleFileUpload">
      <div class="listfiles flex w-full flex-col gap-2">

        
        <div class="listfiles flex w-full flex-col gap-2">
          <div v-for="(pdf, index) in pdfNames" :key="pdf.id" class="flex border hover:bg-neutral-200 px-6 py-2 rounded justify-between">
            <div class="flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-text">
                <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"/>
                <path d="M14 2v4a2 2 0 0 0 2 2h4"/>
                <path d="M10 9H8"/>
                <path d="M16 13H8"/>
                <path d="M16 17H8"/>
              </svg>
              <span>{{ pdf.name }}</span>
            </div>
            <button class="hover:bg-[#F87170] hover:text-white p-2 rounded" @click="deletePdf(pdf.id)">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2">
                <path d="M3 6h18"/>
                <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/>
                <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/>
                <line x1="10" x2="10" y1="11" y2="17"/>
                <line x1="14" x2="14" y1="11" y2="17"/>
              </svg>
            </button>
          </div>
        </div>
        
      </div>
    </div>
  </section>
</template>

<style scoped>
.sidebar {
  max-width: 380px;
}
</style>