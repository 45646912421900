<template>
<div class="screen flex flex-col w-full gap-8 items-center overflow-x-hidden">
  <AppMobile></AppMobile>
  <AppHeader></AppHeader>
  <SliderImg></SliderImg>
  <Social></Social>
  <Cta></Cta>
  <Institutionnels></Institutionnels>
  <GlobalArticles></GlobalArticles>
  <Contact></Contact>
  <AppFooter></AppFooter>
</div>
</template>

<script>

import AppFooter from "@/components/AppFooter.vue";
import AppHeader from "@/components/AppHeader.vue";
import SliderImg from "@/components/Index/SliderImg.vue";
import GlobalArticles from "@/components/Index/GlobalArticles.vue";
import Cta from "@/components/Index/Cta.vue";
import Contact from "@/components/Index/Contact.vue";
import AppMobile from "@/components/AppMobile.vue";
import Institutionnels from "@/components/Index/Institutionnels.vue";
import Social from "@/components/Index/Social.vue";

export default {
  name: 'Index',
  components: {
    AppMobile,
    AppFooter,
    AppHeader,
    SliderImg,
    GlobalArticles,
    Cta,
    Contact,
    Institutionnels,
    Social
  }

}
</script>

<style scoped>
</style>
