<script>
export default {
  name: "HistoireTexte"
}
</script>

<template>
  <div class="flex w-full items-center px-6 justify-center mt-56 titre">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">HISTOIRE MERITE MARITIME</h1>
    </div>
  </div>
  <section class="flex flex-col p-6 justify-center items-center gap-8 w-full mb-24">

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">1901</h1>
      <p>Pour récompenser les bons et loyaux services des marins français, il a d'abord été créé la Médaille d'Honneur des Marins du Commerce et de la Pêche.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">1930</h1>
      <p>Il est institué l'Ordre du Mérite Maritime, destiné à récompenser la valeur professionnelle des marins et le mérite des citoyens qui se sont distingués pour le développement de la Marine Marchande, des Ports, des Pêches et des Sports Nautiques.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">30 mars 1954</h1>
      <h1 class="text-2xl">Création de la Fédération Nationale du Mérite Maritime et de la Médaille d'Honneur des Marins du Commerce et de la Pêche</h1>
      <p>Monsieur D-M. Cossemille, intendant de la Marine Marchande, et M. Antoine Chaix, Officier radioélectricien de 1ère classe de la Marine Marchande, fondèrent une association ayant pour objectif de préserver le prestige de l'Ordre. Cette association dénommée "Fédération Nationale du Mérite Maritime et de la Médaille d'Honneur des Marins du Commerce et de la Pêche", était déclarée le 8 septembre 1954.</p>
      <br>
      <p>Elle est administrée par un conseil d'administration de 15 membres minimum.</p>
      <br>
      <p>Elle a pour buts :</p>
      <br>
      <p>- de veiller au prestige de l'Ordre du Mérite Maritime et de la Médaille d'Honneur.</p>
      <p>- de créer et de maintenir l'union entre tous ses membres par des contacts fréquents.</p>
      <p>- de soutenir moralement et matériellement ses adhérents en difficulté.</p>
      <br>
      <p>La haute valeur du Mérite Maritime a été reconnue et appréciée par le général Charles De Gaulle, qui a maintenu notre Ordre en 1963, après intervention de M. Jean Morin, secrétaire général de la Marine Marchande.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Moyens d'action</h1>
      <p>Liaison permanente avec le ministère et la direction des Affaires Maritimes. Fréquentes réunions d'information. Congrès annuel avec assemblée générale, conférences et débats. Repas amicaux. Publication et diffusion du bulletin (aux alentours du mois d'août) et du mini-bulletin en février. Contact avec toutes les associations de marins et d'anciens marins. Edition de l'annuaire des membres (mise à jour annuelle).La Fédération comprend des sections réparties dans toute la France et l'Outre-mer.</p>
      <br>
      <p>Les ressources principales de la Fédération sont avant tout, les cotisations de ses membres; il faut y ajouter des subventions et intérêts des divers placements. La gamme des cotisations permet à tous de contribuer à l'oeuvre commune.</p>
      <br>
      <p>Il faut retenir que le fait de recevoir le Mérite Maritime ou la Médaille d'Honneur des Marins, et l'honneur qui s'attache à ces distinctions, impliquent pour les titulaires quelques devoirs, dont celui de contribuer à la vie de l'association dont ils font partie.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Les grandes étapes</h1>
      <p><strong>14 février 1902</strong> Institution de la Médaille d'Honneur des Marins du Commerce et de la Pêche.</p>
      <br>
      <p><strong>9 février 1930</strong> Institution de l'Ordre du Mérite Maritime.</p>
      <br>
      <p><strong>30 mars 1954</strong> Création à Marseille de l'Association du Mérite Maritime et de la Médaille d'Honneur des Marins.</p>
      <br>
      <p><strong>8 septembre 1954</strong> Création à Marseille de la Fédération Nationale du Mérite Maritime et de la Médaille d'Honneur des Marins.</p>
      <br>
      <p><strong>3 décembre 1963</strong> Création par le général De Gaulle, de l'Ordre National du Mérite, se substituant à 16 ordres ministériels et coloniaux. L'Ordre du Mérite Maritime est conservé.</p>
      <br>
      <p><strong>26 avril 1980</strong> Cérémonie du Cinquantenaire du Mérite Maritime à Dunkerque.</p>
      <br>
      <p><strong>17 janvier 2002</strong> Décret n° 2002-88, régissant l'Ordre du Mérite Maritime institué par la loi de 1930.</p>
      <br>
      <p><strong>24 mai 2002</strong> Cérémonie du Centenaire de la Médaille d'Honneur des Marins du Commerce et de la Pêche, à Paris.</p>
      <br>
      <p><strong>13 mai 2004</strong> Cérémonie du cinquantenaire de la Fédération à Marseille (à bord du CF "Danielle Casanova").</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Les présidents</h1>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Le mérite maritime</h1>
      <p><strong>L'Ordre du "Mérite Maritime" a été institué par la loi du 9 février 1930</strong> pour récompenser la valeur professionnelle des marins et le mérite des citoyens qui se sont distingués par des services particuliers pour le développement et le rayonnement des activités maritimes. (La formule initiale était : pour le développement de la Marine Marchande, des ports, des pêches et des sports nautiques).</p>
      <br>
      <p><strong>L’ordre du Mérite maritime, qui comprend des chevaliers, des officiers et des commandeurs est régi par les textes ci-après actuellement en vigueur :</strong></p>
      <br>
      <p><strong>- Arrêté du 5 novembre 2009 relatif à la remise des insignes de l’ordre du Mérite maritime.</strong></p>
      <br>
      <p><strong>Circulaire du 13 février 2017 relative à l’instruction des candidatures et promotions pour le Mérite maritime</strong></p>
      <br>
      <p><strong>Décret 2021-1119 du 25 août 2021</strong> pour modification du Décret 2002-88 du 17 janvier 2002 <strong>relatif à l'Ordre du Mérite maritime</strong></p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Principaux renseignements</h1>
      <p>Pour être admis chevalier il faut justifier au moins de dix années de services et d'activités maritimes particuliers et exceptionnels, durée réduite dans le cas d'actes d'héroïsme ou de dévouement en mer.</p>
      <br>
      <p>Pour être promu aux grades d'officier (après 5 ans au grade de chevalier) puis de commandeur (après 5 ans au grade d'officier) il faut justifier, à chaque fois, de mérites nouveaux (précités ci-dessus) depuis la précédente nomination ou promotion.</p>
      <br>
      <p>Les services exceptionnels, notamment les faits d’héroïsme et de dévouement accomplis en mer ainsi que les actes nettement caractérisés concourant au rayonnement du monde maritime, peuvent dispenser des conditions de durée de services, sous la réserve expresse de ne franchir aucun grade.</p>
      <br>
      <p>Les nominations et promotions sont prononcées chaque année à l'occasion du 1er janvier et de la fête nationale du 14 juillet par décret du Premier ministre pris sur les rapports conjoints du ministre chargé de la mer et du ministre des armées rapports reposant eux-mêmes sur les propositions du conseil de l'ordre.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Public des récipiendaires</h1>
      <p>Le nombre de croix du Mérite maritime est fixé pour chaque grade par un arrêté annuel du ministre chargé de la mer, sur proposition du conseil de l'ordre. Elles sont réparties en trois contingents :</p>
      <br>
      <p><strong>Au titre du contingent A</strong>, elles peuvent être attribuées au personnel navigant de la marine marchande, des administrations civiles de l'Etat et des équipages des canots de sauvetage de toute société agréée par l'Etat, ainsi qu'aux personnes s'étant distinguées dans les sports nautiques.</p>
      <br>
      <p><strong>Au titre du contingent B</strong>, elles peuvent être attribuées au personnel militaire du ministère de la défense.</p>
      <br>
      <p><strong>Au titre du contingent C</strong>, elles peuvent être attribuées aux autres personnes qui se sont distinguées pour le développement et le rayonnement des activités maritimes, notamment dans le domaine de la marine marchande, de la pêche, des cultures marines, de l’administration maritime, des services de santé, des industries (construction navale, énergies maritimes renouvelables, activités portuaires) et des services liées à la mer, des associations maritimes (élus et organisations professionnelles et syndicales), de la protection de l’environnement littoral et marin, de la recherche océanographique et maritime, de l’enseignement maritime, de la surveillance et de la sécurité maritime, de l’ingénierie et du conseil maritime, du courtage, de la plaisance, du tourisme maritime, de la préservation et de la valorisation du patrimoine culturel maritime, enfin dans le domaine de la diplomatie maritime.</p>
      <br>
      <p>La croix du Mérite maritime peut être conférée à titre posthume.</p>
      <br>
      <p>Cet arrêté précise notamment les ratios de parité homme/femme imposés pour chacun des trois contingents en cohérence avec les ratios imposés pour les deux ordres nationaux. Pour le contingent B, le ratio de parité est fixé sur avis conforme du ministre de la défense</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Procédure</h1>
      <p>Les propositions au titre des contingents A et C sont établies par les directions départementales des territoires et de la mer. Les dossiers de proposition sont ensuite visés par les préfets de département de résidence et transmis aux directeurs interrégionaux de la mer. Les directions interrégionales de la mer proposent, à leur niveau, des candidatures complémentaires qui seront également visées par les préfets de département.</p>
      <br>
      <p>L'ensemble est transmis au conseil de l’Ordre lequel établit la liste des propositions soumises à la décision du ministre chargé de la mer. Les candidatures et les propositions qui concernent les personnes du contingent B sont transmises par la voie hiérarchique au ministre des armées qui les transmet pour décision au ministre chargé de la mer, après examen par le conseil de l’Ordre.</p>
      <br>
      <p>Le ministre chargé de la mer arrête la liste proposée à l'agrément du Premier ministre.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Insignes</h1>
      <p>Les insignes s'inspirent de symboles marins :</p>
      <br>
      <p>Une étoile en forme de rose des vents à seize branches sur laquelle est appliquée une ancre. Les huit branches principales sont ornées d'émail blanc. Au centre de l'avers figure une effigie de la République française vue de face et en exergue l'inscription "REPUBLIQUE FRANCAISE" sur fond d'émail bleu. Au centre du revers, sont inscrits les mots "MERITE MARITIME" avec en exergue la mention "MARINE MARCHANDE" sur fond d'émail bleu. Le diamètre de l'insigne est de 40mm en argent pour les chevaliers, 40mm en vermeil pour les officiers et de 57mm en vermeil pour les commandeurs. L'insigne est suspendu à un ruban d'une largeur de 37mm, bleu outremer avec deux liserés verts sur chaque bord; il comporte une rosette pour les officiers. L'insigne des commandeurs est suspendu à une cravate.</p>
    </div>

    <div class="w-full max-w-screen-lg text-xl">
      <h1 class="text-2xl">Cérémonial de remise des insignes du Mérite Maritime</h1>
      <p>Aucune cérémonie de remise des insignes de l'Ordre du Mérite Maritime n'est obligatoire aux termes des textes qui régissent cet Ordre toutefois le cérémonial est encadré par l'arrêté du 5 novembre 2009.</p>
      <br>
      <p>Lorsqu’il n’est pas procédé à la remise de l’insigne de l’Ordre du Mérite maritime par un membre de cet Ordre d’un grade au moins égal à celui du récipiendaire, cet insigne peut également être remis par :</p>
      <br>
      <p>– le représentant de l’Etat dans le département ;</p>
      <p>– l’ambassadeur, chef de mission diplomatique, lorsque la remise a lieu sur le territoire d’un Etat étranger ;</p>
      <p>– un membre de l’ordre de la Légion d’honneur ou de l’ordre national du Mérite, d’un grade au moins égal à celui du récipiendaire ;</p>
      <p>– un membre du conseil de l’ordre du Mérite maritime ;</p>
      <p>– le chef de service départemental chargé des affaires de la mer.</p>
      <br>
      <p>La remise de l’insigne est accompagnée de la phrase suivante :</p>
      <br>
      <p>« X (prénom), Y (nom), au nom du Gouvernement de la République, nous vous faisons chevalier (officier ou commandeur) du Mérite maritime. »</p>
      <br>
      <p>Celui (ou celle) qui remet la décoration devra, lors de la cérémonie, être porteur de sa (ou de ses) décoration(s) pendante(s). Il est d'usage, avant la remise de l'insigne proprement dite, de rappeler brièvement dans une allocution, la carrière du récipiendaire, ses mérites et ses valeurs. La remise de l'insigne est suivie d'un geste amical à l'égard du décoré (poignée de main) qui marque la relation, le respect et l'hommage à cette personne.</p>
      <br>
      <p>Le décoré est invité à exprimer ses remerciements au regard de l'honneur reçu et auprès de ceux qui ont compté dans son parcours.
        Une tenue soignée, le port des insignes honorifiques, un éloge amical nourri de souvenirs personnels et d'émotions sont des garanties de valorisation des honneurs transmis. La cérémonie est un moment solennel qui marque l'attachement des personnes présentes à l'Ordre du Mérite maritime.</p>
    </div>
  </section>
</template>

<style scoped>
@media (max-width: 1024px) {
  .titre{
    margin-top: 100px;
  }
}
</style>