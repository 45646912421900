<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";
import MedailleTexte from "@/components/Medaille/MedailleTexte.vue";
import AppMobile from "@/components/AppMobile.vue";

export default {
  name: "Medaille",
  components:{
    AppMobile,
    MedailleTexte,
    AppHeader,
    AppFooter
  }
}
</script>

<template>
  <div class="screen flex flex-col size-full gap-8 items-center">
    <AppHeader></AppHeader>
    <AppMobile></AppMobile>
    <MedailleTexte></MedailleTexte>
    <AppFooter></AppFooter>
  </div>
</template>

<style scoped>

</style>