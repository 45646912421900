<template>
  <div class="flex w-full items-center px-6 justify-center mt-56 titre">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">{{ nom }}</h1>
    </div>
  </div>
  <div class="flex w-full max-w-screen-lg px-6">
    <div class="relative w-full mx-auto">
      <div class="overflow-hidden relative">
        <div
          class="flex transition-transform duration-500 ease-in-out"
          :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
        >
          <div v-for="(image, index) in images" :key="index" class="min-w-full">
            <img :src="image.url" class="w-full flex" />
          </div>
        </div>
      </div>
      <button
        @click="prevSlide"
        class="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 m-2 hover:bg-opacity-100 transition-all"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-move-left"
        >
          <path d="M6 8L2 12L6 16" />
          <path d="M2 12H22" />
        </svg>
      </button>
      <button
        @click="nextSlide"
        class="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white bg-opacity-50 rounded-full p-2 m-2 hover:bg-opacity-100 transition-all"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-move-right"
        >
          <path d="M18 8L22 12L18 16" />
          <path d="M2 12H22" />
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'CongresImg',
  data() {
    return {
      currentIndex: 0,
      images: [],
      nom: '',
    };
  },
  methods: {
    prevSlide() {
      this.currentIndex =
        this.currentIndex > 0 ? this.currentIndex - 1 : this.images.length - 1;
    },
    nextSlide() {
      this.currentIndex =
        this.currentIndex < this.images.length - 1 ? this.currentIndex + 1 : 0;
    },
  },
  mounted() {
    const pathArray = window.location.pathname.split('/');
    const congreId = pathArray[pathArray.length - 1];

    if (congreId) {
      axios
        .get(`https://api.meritemaritime-fnmm.com/congre/${congreId}`)
        .then((response) => {
          this.images = response.data.map(image => ({ url: image.url }));
          if (response.data.length > 0) {
            this.nom = response.data[0].nom;
          }
        })
        .catch((error) => {
          console.error('There was an error fetching the images:', error);
        });
    }
  },
};
</script>

<style scoped>
@media (max-width: 1024px) {
  .titre {
    margin-top: 100px;
  }
}

img {
  height: 600px;
  object-fit: cover;
}
</style>
