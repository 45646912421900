<template>
  <div class="flex w-full h-full flex-col p-6 overflow-y-auto overflow-x-hidden">
    <div class="box w-full flex-col gap-4 flex">
      <input type="text" name="h1" id="h1" placeholder="Titre principal" class="flex w-full px-6 py-4 bg-neutral-100 rounded" v-model="article.title">
      <label for="file" class="cursor-pointer npflex bg-[#60A5FA] text-white px-6 py-4 rounded justify-center">Ajouter une image</label>
      <input type="file" name="img" class="hidden" id="img" typeof="png/jpg/jpeg">
      <hr class="flex border w-full">
      <label for="file" class="cursor-pointer flex bg-[#60A5FA] text-white px-6 py-4 rounded justify-center">Ajouter un pdf</label>
      <input type="file" class="hidden" name="file" id="file" typeof="pdf">

      <div class="listfiles flex w-full flex-col gap-2">
        <div class="flex border hover:bg-neutral-200 px-6 py-2 rounded justify-between">
          <div class="flex items-center gap-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-text"><path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"/><path d="M14 2v4a2 2 0 0 0 2 2h4"/><path d="M10 9H8"/><path d="M16 13H8"/><path d="M16 17H8"/></svg>
            <span>Congrès 2039 Mirine.pdf</span>
          </div>
          <button class="hover:bg-[#F87170] hover:text-white p-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-trash-2"><path d="M3 6h18"/><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"/><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg>
          </button>
        </div>
      </div>

      <hr class="flex border w-full">


      <textarea name="text" id="text" placeholder="Sujet" class="bg-neutral-100 flex w-full p-6" v-model="article.description"></textarea>
    </div>
    <button @click="saveChanges" class="flex w-full justify-center items-center bg-blue-400 px-6 py-4 text-white hover:bg-blue-300 rounded mt-4">Publier</button>
    <button class="flex w-full justify-center items-center bg-neutral-400 px-6 py-4 text-white hover:bg-neutral-300 rounded mt-4">Annuler</button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'VueArticle',
  data() {
    return {
      article: {
        title: '',
        description: ''
      }
    };
  },
  mounted() {
    this.retrieveEditedArticle();
  },
  watch: {
    'article.title': function(newTitle) {
      this.updateLocalStorage();
    },
    'article.description': function(newDescription) {
      this.updateLocalStorage();
    }
  },
  methods: {
    retrieveEditedArticle() {
      const editedArticle = localStorage.getItem('editedArticle');
      if (editedArticle) {
        try {
          const parsedArticle = JSON.parse(editedArticle);
          if (parsedArticle && typeof parsedArticle === 'object' && 'title' in parsedArticle && 'description' in parsedArticle) {
            this.article = parsedArticle;
          }
        } catch (error) {
          console.error('Error parsing editedArticle from local storage:', error);
        }
      }
    },
    updateLocalStorage() {
      localStorage.setItem('editedArticle', JSON.stringify(this.article));
    },
    async saveChanges() {
  try {
    const editedArticleId = localStorage.getItem('editedArticleId');
    const id_article = localStorage.getItem('id_article');
    const response = await axios.post(`https://api.meritemaritime-fnmm.com/backoffice/article/edit/${id_article}`, {
      editedArticleId: editedArticleId,
      id_article: id_article,
      ...this.article
    });
    console.log('Article updated successfully:', response.data);
    this.updateLocalStorage();
  } catch (error) {
    console.error('Error updating article:', error);
  }
}
,
    refreshComponent() {
      this.retrieveEditedArticle();
    }
  }
};
</script>

<style scoped>
textarea {
  min-height: 300px;
}
</style>
