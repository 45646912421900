<script>
import axios from 'axios';
import AppFooter from "@/components/AppFooter.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppMobile from "@/components/AppMobile.vue";

export default {
  name: "Connexion",
  components: {
    AppMobile,
    AppFooter,
    AppHeader
  },
  data() {
    return {
      form: {
        username: '',
        password: '',
        save: false
      }
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const response = await axios.post('https://api.meritemaritime-fnmm.com/login', {
          username: this.form.username,
          password: this.form.password
        });
        console.log('Success:', response.data);
               const token = response.data.token;
        
        // Stockez le token dans le local storage
        localStorage.setItem('token', token);
        window.location.href = '/espace-membre/panel'; //Will take you to Google.

                // Vous pouvez ajouter ici du code pour gérer la réponse, comme rediriger l'utilisateur ou afficher un message de succès
      } catch (error) {
        console.error('Error:', error);
        // Vous pouvez ajouter ici du code pour gérer les erreurs, comme afficher un message d'erreur
      }
    }
  }
}
</script>

<template>
  <div class="screen flex flex-col size-full items-center">
    <AppMobile></AppMobile>
    <AppHeader></AppHeader>
    <div class="screen flex size-full h-dvh justify-center items-center overflow-hidden mt-24 relative bg-neutral-100">
      <form @submit.prevent="handleSubmit" class="gap-2 flex flex-col w-full max-w-screen-sm p-6 items-center">
        <div class="input text-neutral-800 flex items-center bg-white p-2 rounded shadow w-full">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail"><rect width="20" height="16" x="2" y="4" rx="2"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/></svg>
          <input v-model="form.username" type="text" name="username" id="username" placeholder="username" required class="bg-none flex px-2 outline-0 bg-white min-h-10 w-full">
        </div>
        <div class="input text-neutral-800 flex items-center bg-white p-2 rounded shadow w-full">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-lock"><rect width="18" height="11" x="3" y="11" rx="2" ry="2"/><path d="M7 11V7a5 5 0 0 1 10 0v4"/></svg>
          <input v-model="form.password" type="password" name="password" id="password" placeholder="Mot de passe" required class="bg-none flex px-2 outline-0 bg-white min-h-10 w-full">
        </div>
        <div class="save flex items-center gap-2 justify-start w-full mb-4">
          <input v-model="form.save" type="checkbox" name="save" id="save" class="flex w-4 h-4 cursor-pointer">
          <label for="save" class="drop-shadow-xl cursor-pointer font-semibold">Se souvenir de moi</label>
        </div>
        <button type="submit" class="p-4 bg-[#3E5C8E] text-white font-semibold rounded hover:bg-[#072653] w-full">Se connecter</button>
        <a href="/contact" class="flex items-center gap-2 mt-2 drop-shadow-xl hover:text-blue-700"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-circle-help"><circle cx="12" cy="12" r="10"/><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/><path d="M12 17h.01"/></svg> Besoin d'aide ?</a>
      </form>
    </div>
    <AppFooter></AppFooter>
  </div>
</template>

<style scoped>
form {
  max-width: 450px;
}

img {
  min-width: 544px;
  z-index: -1;
}

@media (max-width: 500px) {
  img {
    height: 100vh;
    object-fit: cover;
    background: no-repeat;
  }
}
</style>
