
<script>
import axios from 'axios';

export default {
  name: "SectionsTexte",
  data() {
    return {
      sections: [] // Initialiser le tableau de sections
    };
  },
  computed: {
    sectionRows() {
      const rows = [];
      for (let i = 0; i < this.sections.length; i += 3) {
        rows.push(this.sections.slice(i, i + 3));
      }
      return rows;
    }
  },
  methods: {
    chargerSections() {
      axios.post('https://api.meritemaritime-fnmm.com/section/list')
        .then(response => {
          this.sections = response.data; // Mettre à jour 'sections' avec les données de la réponse
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des sections :', error);
        });
    }
  },
  mounted() {
    this.chargerSections(); // Appeler la méthode 'chargerSections' lorsque le composant est monté
  }
}
</script>
<template>
  <div class="flex w-full items-center px-6 justify-center mt-56 titre">
    <div class="w-full flex max-w-screen-lg">
      <h1 class="text-4xl border-b-4 pb-2 border-[#072653]">Les sections</h1>
    </div>
  </div>

  <section v-for="(row, index) in sectionRows" :key="index" class="flex w-full items-center justify-center p-6">
    <div class="containt flex w-full gap-8 max-w-screen-lg">
      <div v-for="(section, secIndex) in row" :key="secIndex" class="widget flex border p-2 gap-2 shadow flex-col items-center w-full border-b-4 border-[#072653]">
        <h1 class="text-2xl text-center">{{ section.nom }}</h1>
        <hr class="border w-full flex">
        <a :href="`/sections/${section.id_section_familly}`" class="btn bg-[#072653] text-white px-6 py-2 rounded">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-move-right">
            <path d="M18 8L22 12L18 16"/>
            <path d="M2 12H22"/>
          </svg>
        </a>
      </div>
    </div>
  </section>

</template>

<style scoped>
@media (max-width: 800px){
  .containt{
    flex-direction: column;
  }
}

@media (max-width: 1024px) {
  .titre{
    margin-top: 100px;
  }
}
</style>