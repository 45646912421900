<script>
import axios from 'axios';

export default {
  name: 'AccueilDash',
  data() {
    return {
      pdfs: [], // Ajouter un data property pour stocker les PDF
    };
  },
  mounted() {
    // Récupérer le token depuis le localStorage
    const token = localStorage.getItem('token');

    // Liste des tokens à vérifier
    const specialToken = '61d46ebd6dd7a90cc1adf57898c81ecafuguman';
    const validTokens = ['323fugu46ebd6dd7a90cc1adf57898c81eca', '61d2343d6dd7a90cc1adf57898c81eca'];
    const adminTokens = [
      '61d46ebd6dd7a90cc1adf57898c81eca',
      'qdsdsqbd6dd7a90cc1adf57898c81eca',
      '4114aba37fabdb62303c7ea766ec7bd8',
      '796cf7aec1d8a86a0c8cc5c34c386ae6',
      '644b32e558f8800e4a53a9385f23bc0f',
      '434848b68ff2c0740bb67f18514d487a',
      '423fda34364871792790f4fe325e02aa',
      '5f1d823941fe154c1476722b984c4650',
      '72ebfae1a6858ce41fd63c714d09a8e1',
      '0770e0c9f9ffb4496293b1cd74dec9a7',
      'f0656ce4121fae47653b6b1d55ec9206',
      '45ecea54853a10f3d7e32ec03376c211',
      '7601e3deb936d631878b97daddae74d4',
      '23df8ff46a29fd04daf106f55be906ce',
      '9d7d34caa485ebab5c4d1a57aa65019a',
      'b3265b4db01777b1bedf812c86579525',
      '61d46ebd6dd7a90cc1adf57898c81eca',
      'ed73dd809f318e7cb7d984f8a707fcc6',
      '638c99c36fba53e170215ec5f13c47c6',
      'c97f27d1c7957fb0fff9c9b2f3cf8f56',
      'b077bc12b74145051cae424b9cafcecb',
      '45bc27a2e1616224c5ad945dadd6ffe6',
      'c4122cb945d7cfb32aae54345e54fc58',
      '2696adb2d5b6f5cc1b7b4fd650cc9544',
      '456a0aa25b07ea81f45b89f6eae990a3'
    ];

    // Redirection en fonction du token
    if (token === specialToken) {
      this.$router.push('/espace-membre/membre');
    } else if (validTokens.includes(token)) {
      this.$router.push('/espace-membre/panel');
    } else if (adminTokens.includes(token)) {
      this.$router.push('/espace-membre/admin');
    } else {
      this.$router.push('/');
    }

    // Charger les PDFs après la redirection
    this.loadPdfs();
  },
  methods: {
    loadPdfs() {
      axios.get('https://api.meritemaritime-fnmm.com/backoffice/getpdfall')
        .then(response => {
          // Filtrer les PDF avec le rôle "user"
          this.pdfs = response.data.filter(pdf => pdf.role === 'user');
        })
        .catch(error => {
          console.error('Erreur lors de la récupération des PDF :', error);
        });
    },
    logout() {
      localStorage.clear(); // Vider tout le localStorage
      this.$router.push('/'); // Rediriger vers la route '/'
    }
  }
}
</script>

<template>
  <section class="w-full flex h-full p-6 gap-4 flex-col">
    <a href="/" @click.prevent="logout" class="bg-red-400 text-white px-4 py-2 rounded">Déconnexion</a>

    <h1 class="text-2xl">Espace Membres</h1>
    <div class="list flex w-full flex-col gap-2 overflow-x-hidden overflow-y-auto">
      <div v-for="pdf in pdfs" :key="pdf.id" class="flex border hover:bg-neutral-200 px-6 py-2 rounded justify-between">
        <div class="flex items-center gap-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-file-text">
            <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z"></path>
            <path d="M14 2v4a2 2 0 0 0 2 2h4"></path>
            <path d="M10 9H8"></path>
            <path d="M16 13H8"></path>
            <path d="M16 17H8"></path>
          </svg>
          <span>{{ pdf.name }}</span>
        </div>
        <div class="flex gap-2">
          <a :href="pdf.url" target="_blank" class="hover:bg-green-400 hover:text-white p-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-download">
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
              <polyline points="7 10 12 15 17 10"/>
              <line x1="12" x2="12" y1="15" y2="3"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>
